import React, { Suspense, lazy } from "react";
import LoadingScreen from "./Components/LoadingScreen";
import { Route, Routes } from "react-router-dom";

const AppRouter = () => {
  const Home  = lazy(()=>import('./View/Home'))
  const AboutPage  = lazy(()=>import('./View/AboutPage'))
  const LegalPage  = lazy(()=>import('./View/LegalPage'))
  const TermsPage  = lazy(()=>import('./View/TermsPage'))
  const PrivacyPage  = lazy(()=>import('./View/PrivacyPage'))
  const DeletePage  = lazy(()=>import('./View/DeletePage'))
  const CareersPage  = lazy(()=>import('./View/CareersPage'))
  const CareersDetail  = lazy(()=>import('./View/CareersDetail'))
  const EstimatePage  = lazy(()=>import('./View/EstimatePage'))
  const ContactPage  = lazy(()=>import('./View/Contact'))
  const AttendifyPage  = lazy(()=>import('./View/products/attendify/AttendifyPage'))
  const SYMPage  = lazy(()=>import('./View/products/sym/SYMPage'))
  const ACEPage  = lazy(()=>import('./View/products/ace/ACEPage'))
  const SecureEyePage  = lazy(()=>import('./View/products/secureeye/SecureEyePage'))
  const WebAppDevelopement  = lazy(()=>import('./View/services/webAppDevelopement/WebAppDevelopement'))
  const MetaversePage  = lazy(()=>import('./View/services/metaverse/MetaversePage'))

  return (
    <Routes>
     <Route path="/" element={<Suspense fallback={<LoadingScreen />}><Home /></Suspense>} />
       <Route path="/about" element={<Suspense fallback={<LoadingScreen />}><AboutPage /></Suspense>} />
          <Route path="/contact" element={<Suspense fallback={<LoadingScreen />}><ContactPage /></Suspense>}/>
          <Route path="/legal" element={<Suspense fallback={<LoadingScreen />}><LegalPage /></Suspense>}/>
          <Route path="/termsCondition" element={<Suspense fallback={<LoadingScreen />}><TermsPage /></Suspense>}/>
          <Route path="/privacy" element={<Suspense fallback={<LoadingScreen />}><PrivacyPage /></Suspense>}/>
          <Route path="/deleteData" element={<Suspense fallback={<LoadingScreen />}><DeletePage /></Suspense>}/>
          <Route path="/careers" element={<Suspense fallback={<LoadingScreen />}><CareersPage /></Suspense>}/>
          <Route path="/careersDetail/:id" element={<Suspense fallback={<LoadingScreen />}><CareersDetail /></Suspense>}/>
          <Route path="/estimatepage" element={<Suspense fallback={<LoadingScreen />}><EstimatePage /></Suspense>}/>
          <Route path="/attendify" element={<Suspense fallback={<LoadingScreen />}><AttendifyPage /></Suspense>}/>
          <Route path="/sym" element={<Suspense fallback={<LoadingScreen />}><SYMPage /></Suspense>}/>
          <Route path="/ace" element={<Suspense fallback={<LoadingScreen />}><ACEPage /></Suspense>}/>
          <Route path="/secureye" element={<Suspense fallback={<LoadingScreen />}><SecureEyePage /></Suspense>}/>
          <Route path="/web&appdevelopement" element={<Suspense fallback={<LoadingScreen />}><WebAppDevelopement /></Suspense>}/>
          <Route path="/metaverse" element={<Suspense fallback={<LoadingScreen />}><MetaversePage/> </Suspense>}/>
    </Routes>
  );
};
// testing
export default AppRouter;